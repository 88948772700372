@import "../../assets/styles/mixins/flex.scss";
@import "../../assets/styles/typography.scss";

.footer {
  font-family: var(--font-family-uni-sans);
  @include flex(row, center, center);
  @include text-16-16-400;
  flex: 0 0 auto;
  width: 100%;
  color: #373868;
  padding: 0 15px;
  font-weight: 600;
  backdrop-filter: blur(25px);
  z-index: 1;
  background: white;

  &__container {
    position: relative;
    @include flex(row, space-between, center);
    max-width: 1080px;
    width: 100%;
    padding: 0 15px 60px;

    @media (max-width: 800px) {
      padding: 0 15px 60px;
    }
  }

  &__icon {
    @include flex();
    margin-bottom: 54px;
  }

  &__phone {
    @include flex(row, flex-start, center);
    gap: 10px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  &__mail {
    @include flex(row, flex-start, center);
    gap: 10px;
    margin-bottom: 30px;
    font-weight: 400;
  }

  &__socials {
    @include flex();
    gap: 14px;
    margin-bottom: 30px;
  }

  &__social {
    transition: all linear 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__inner {
    @include flex(row, space-between, flex-start);
    width: 100%;
    margin-top: -68px;

    @media (max-width: 800px) {
      @include flex(column-reverse);
    }
  }

  &__nav-container {
    @include flex(row, flex-start, flex-start, null);

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__nav {
    @include flex(column, flex-start);
    gap: 16px;
    margin-top: 75px;
    margin-bottom: 14px;
    width: 255px;

    &-right {
      padding-left: 15px;

      @media (max-width: 800px) {
        padding-left: 0;
        margin-bottom: 60px;
      }
    }

    @media (max-width: 800px) {
      margin-top: 0;
    }
  }

  &__arrow {
    transition: all linear 0.1s;
  }

  &__copyright {
    font-weight: 400;
  }

  &__link {
    transition: all linear 0.2s;
    width: fit-content;

    &:hover {
      opacity: 0.3;
    }

    &-news {
      margin-bottom: 22px;

      @media (max-width: 800px) {
        margin-bottom: -8px;
      }
    }

    &-connect {
      display: inline-flex;
      padding: 10px 14px;
      margin-left: -14px;
      border-radius: 100px;
      background: #eef3f9;
      cursor: pointer;

      @media (max-width: 800px) {
        margin-bottom: 22px;
      }
    }
  }
}
