@mixin text-16-16-400 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}

@mixin text-18-22-600 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0;
}

@mixin text-18-22-400 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0;
}
