@import "./assets/styles/fonts.scss";
@import "./assets/styles/mixins/flex.scss";

$font-family-uni-sans: "Uni Sans";
$font-family-inter: "Inter";
$font-family-segoe: "Segoe UI", sans-serif;
$font-family-roboto: "Roboto", sans-serif;

$color-bg-dark: #000625;
$color-bg-gray: #f0f0f0;
$color-text-main: #1a1f36;
$color-border-gray: #dbdeec;

:root {
  --font-family-uni-sans: #{$font-family-uni-sans};
  --font-family-inter: #{$font-family-inter};
  --font-family-segoe: #{$font-family-segoe};
  --font-family-roboto: #{$font-family-roboto};

  --color-bg-dark: #{$color-bg-dark};
  --color-bg-gray: #{$color-bg-gray};
  --color-text-main: #{$color-text-main};
  --color-border-gray: #{$color-border-gray};
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  color: inherit;
}

body {
  font-family: "Inter";
  font-weight: 400;
  font-display: block;
}

/* body { */
/* font: var(--zn-font-base);
  background-color: var(--zn-color-bg);
  color: var(--zn-color-fg); */
/* } */

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100%;
}

main {
  width: 100%;
  flex: 1 0 auto;
  z-index: 1;
}

h1 {
  font-size: 40px;
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  font-display: block;

  @media (max-width: 800px) {
    font-size: 25px;
  }
}

h2 {
  font-size: 34px;
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 15px;
  margin-left: -15px;
  border-left: 2px solid #0096e9;

  @media (max-width: 800px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }
}

h3 {
  font-family: var(--font-family-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-left: 2px solid #0096e9;
  padding-left: 15px;
  margin-left: -15px;

  @media (max-width: 800px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

section {
  position: relative;
  @include flex(column, center, center);
  padding: 0 15px;
  height: fit-content;
  width: 100%;
}

.container {
  @include flex(column, flex-start, center);
  max-width: 1080px;
  width: 100%;
  padding: 60px 15px;
  overflow-x: hidden;

  @media (max-width: 800px) {
    padding: 40px 15px;
  }
}

.App__container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.App__container::-webkit-scrollbar-track {
  background-color: rgba(208, 208, 208, 0.2);
}

.App__container::-webkit-scrollbar-thumb {
  background-color: rgba(133, 133, 133, 0.8);
}

.br {
  @media (max-width: 1000px) {
    display: none;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #d2dbe9;
  width: 19px;
  height: 19px;
  border: 1px solid #d2dbe9;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  position: absolute;
  left: 3px;
  top: -1px;
  content: url(assets/icons/check.svg);
  width: 9px;
  height: 7px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
