@import "../../assets/styles/mixins/flex.scss";
@import "../../assets/styles/typography.scss";

.header {
  position: relative;
  top: 0;
  color: white;
  font-family: var(--font-family-roboto);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include flex(row, center, center, nowrap);
  // margin-top: 30px;
  align-items: center;
  // padding: 30px;
  flex: 0 0 auto;
  width: 100%;
  // background: rgba(255, 255, 255, 0.10);
  // backdrop-filter: blur(20px);
  z-index: 2;
  font-display: block;

  @media (max-width: 800px) {
    margin-top: 0;
  }


  &-dropdown {
    align-items: center;
    position: relative;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    &>span {cursor: pointer;}

    .ui-icon {
      margin-left: 5px;
      margin-top: 2px;
      width: 13px;
      height: 7px;
      flex-shrink: 0;
    }

    &:hover {
      .ui-icon {
        transform: rotate(180deg);
      }
      .header-dropdown-content {display: block;}
      .header-dropdown-title {
        opacity: 0.5;
      }
    }

    &-content {
      padding-top: 25px;
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      z-index: 99;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #fff;
        top: 20px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }

    &-title {
      
      transition: opacity linear 0.2s;
    }

    &-inner {
      padding: 18px 50px 18px 30px;
      background-color: #fff;
      color: #373868;
      overflow: hidden;
      border-radius: 4px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      box-shadow: #00053226 0px 6px 10px;


      & > a {
        padding: 12px 0;
        display: flex;
        gap: 10px;
        align-items: center;
        text-wrap: nowrap;
  
        &:hover {opacity: 0.8;}
      }

      svg {
        flex-shrink: 0;
        width: 15px;
        height: 15px;
      }
    }


    &-title {
      &:hover {}
    }
  }



  &-light {
    color: black;
    background: #f6f9fc;
    border-bottom: 1px dashed rgba(221, 225, 236, 0.5);
  }

  &__container {
    position: relative;
    @include flex(row, space-between, center, nowrap);
    max-width: 1080px;
    padding-left: 15px;
    width: 100%;
    min-height: 121px;
    font-display: block;

    @media (max-width: 1143px) {
      min-height: 0;
      padding: 30px 0;
      padding-left: 0;
    }
  }

  &__logo {
    @include flex();
    cursor: pointer;
    margin-right: 30px;
    transition: 0.2s;

    &--mobile {
      justify-self: flex-start;
    }

    &-container {
      @include flex(row, null, center, nowrap);

      @media (max-width: 260px) {
        display: none;
      }
    }
  }

  &__transform {
    visibility: hidden;
    opacity: 0;
  }

  &__signin {
    @include flex(row, flex-start, center, nowrap);
    gap: 30px;
    cursor: pointer;
    transition: all linear 0.2s;
  }

  &-nav {
    @include flex(row, space-between, null, nowrap);
    gap: 30px;
    margin-right: 80px;

    &__link {
      transition: all linear 0.2s;

      &:hover {
        opacity: 0.5;
      }
    }

    &__connect {
      @include flex(column, center, center, nowrap);
      border-radius: 100px;
      background: rgba(255, 255, 255, 0.2);
      height: 36px;
      padding: 0 15px;
      transition: all linear 0.2s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__icons {
    @include flex(row, center, center, nowrap);
    gap: 10px;
  }

  &__burger {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    gap: 15px;

    &-icon {
      cursor: pointer;
    }

    @media (max-width: 1143px) {
      padding-left: 30px;
      padding-right: 12px;
    }
  }

  &__border {
    border-right: 1px solid white;
    height: 16px;
    opacity: 0.3;
  }
}
