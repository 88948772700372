@import "../../assets/styles/mixins/flex.scss";
@import "../../assets/styles/typography.scss";

.about-page {
  &__background {
    background: linear-gradient(61deg, #0075ff 21.22%, #c3eaff 66.15%);
    height: 344px;
    left: -50vw;
    position: absolute;
    top: 217px;
    transform: rotate(-11deg);
    width: 198vw;
    z-index: -1;
  }

  &__section {
    position: relative;
    background: #f6f9fc;

    @media (max-width: 800px) {
      padding: 0;
    }
  }


  &__container {
    position: relative;
    @include flex(column, flex-start, center, nowrap);
    overflow: visible;
    padding: 0;
    z-index: 0;
  }

  &__content {
    width: 100%;
    margin-top: 136px;
    border-radius: 8px;
    padding: 40px 60px 80px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 8, 79, 0.25);
    margin: 136px 0px 0;
    margin-bottom: 200px;

    @media (max-width: 1100px) {
      margin-top: 0;
    }

    @media (max-width: 670px) {
      padding: 40px 15px;
      width: 100vw;
      border-radius: 0;
    }
  }

  &__title {
    font-size: 65px;
    text-align: center;
    @media (max-width: 900px) {
      font-size: 45px;
    }
  }


  h4 {
    padding-top: 40px;
    padding-bottom: 15px;
    font-size: 34px;
    font-weight: 600;
  }

  p {
    margin: 15px 0 10px;
    text-align: left;
    font-size: 18px;
  }

  &__hero {
    padding-top: 30px;
    display: flex;
    gap: 40px;

    img {
      align-self: flex-start;
    }

    p {
      margin-top: 0;
    }

    ul {
      padding-left: 20px;
      list-style-type: disc;
    }
    
    @media (max-width: 900px) {
      flex-direction: column;
      img {
        width: 100%;
      }
    }
  }

  &__our-team {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 900px) {
      gap: 40px;
    }

    .our {
      &-person {
        display: flex;
        gap: 15px;
        
        @media (max-width: 900px) {
          flex-direction: column;
        }
      }

      &-description {
        p {margin: 0;}
      }

      &-img {
        width: 300px;
        height: 300px;
        flex-shrink: 0;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }

        
        @media (max-width: 700px) {
          width: 100%;
          height: auto;
          img {
            width: 100%;
          }
        }
      }

      &-name {
        padding-bottom: 15px;
        font-size: 24px;
        font-weight: 600;
      }

      &-post {
        padding-bottom: 15px;
        font-size: 20px;
      }
    }
  }
}
