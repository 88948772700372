@import "../../../assets/styles/mixins/flex.scss";
@import "../../../assets/styles/typography.scss";

.swiper {
  height: 100%;
  background: transparent;
  width: calc(80vw);

  @media (max-width: 1312px) {
    width: 100% !important;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.swiper-slide .hero__slide {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid #1a1f36 !important;
  background: white !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  border: 1px solid #1a1f36 !important;
  background: #373868 !important;
}

.swiper-pagination {
  position: relative !important;
  width: 1050px !important;
  margin-bottom: 87px;
  margin-top: -50px;

  @media (max-width: 1312px) {
    width: 100% !important;
  }
}

.hero {
  &__section {
    position: relative;
    // background: #FFF;
  }

  &__main {
    // border: 1px solid black;
    width: 100px;
    height: 200px;
  }

  &__container {
    width: 100%;
    position: relative;
    padding-top: 128px;
    padding-bottom: 0;
    overflow: visible;
    @include flex(column, center, flex-start, nowrap);
    z-index: 1;

    @media (max-width: 1100px) {
      padding-top: 40px;
    }

    @media (max-width: 500px) {
      align-items: flex-start;
    }

    @media (max-height: 550px) {
      height: 100%;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
  }

  &__bim {
    padding-top: 12px;
  }

  &__icons {
    @include flex(row, flex-start, center);
    gap: 30px;
    margin-bottom: 30px;

    color: #0f1334;
    font-family: var(--font-family-uni-sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
  }

  &__know {
    font-size: 112px;
    line-height: 101px;
    letter-spacing: -5.6px;

    @media (max-width: 800px) {
      font-size: 74px;
      line-height: 61px;
    }
  }

  &__scada {
    font-size: 112px;
    line-height: 122px;
    letter-spacing: -5.6px;

    @media (max-width: 800px) {
      font-size: 74px;
      line-height: 75px;
    }
  }

  &__eagle-mobile {
    margin-bottom: 10px;
  }

  &__icons-inner {
    @include flex(row, flex-start, center, nowrap);
    gap: 15px;

    .hero__icon-bim {
      margin-bottom: -2px;
    }
  }

  &__top {
    @include flex(row, null, null, nowrap);
    // gap: 33px;
    margin-bottom: 60px;

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__right {
    height: 544px;
    width: 864px;
    image-rendering: pixelated;
    // width: calc(50vw + 32px);

    @media (max-width: 1100px) {
      max-width: 864px;
      width: 100%;
      height: 100%;
    }
  }

  &__left {
    max-width: 510px;

    @media (max-width: 1100px) {
      max-width: 100%;
    }
  }

  &__switcher {
    width: 100%;
    @include flex(row, center, center);
    margin-bottom: 80px;
  }

  &__description {
    text-align: left;
  }

  &__buttons {
    margin-top: 20px;
    @include flex(row, flex-start);
    gap: 28px;
  }

  &__button {
    @include flex(column, center);
    font-family: var(--font-family-uni-sans);
    border-radius: 100px;
    text-align: center;
    font-family: Uni Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 3px 14px;
    height: 36px;
    width: fit-content;
    cursor: pointer;
    transition: all linear 0.2s;

    &:hover {
      opacity: 0.3;
    }
  }

  &__button-blue {
    color: #fff;
    background: #101541;
  }

  &__slider {
    position: relative;
    min-width: 0;
    height: 342px;
    width: 100%;
    margin-bottom: 80px;

    @media (max-width: 500px) {
      width: 50%;
      margin-bottom: 30px;
    }
  }

  &__slide {
    width: 100%;
  }

  &__swiper {
    width: 500px;
  }

  &__partners {
    @include flex(row, space-between, flex-start, nowrap);
    width: 100%;
    margin-bottom: 109px;

    &-img {
      max-height: 60px;
      max-width: 240px;
      width: 100%;

      @media (max-width: 500px) {
        transform: scale(1.5);
      }
    }

    &-ntr {
      @media (max-width: 500px) {
        transform: scale(1);
      }
    }

    &-mobile {
      @include flex(column, flex-start, center);
      width: 100%;
      gap: 40px;

      @media (max-width: 800px) {
        margin-bottom: 80px;
      }
    }

    &-line {
      @include flex(row, center, center, nowrap);
      width: 100%;
      gap: 60px;
      height: 90px;
    }

    &-icon {
      max-width: 145px;
      width: 100%;
    }
  }

  &__title {
    width: 100%;
    text-align: left;
    font-family: var(--font-family-inter);
    color: black;
    font-weight: 700;
    font-size: 65px;
    line-height: 77px;
    letter-spacing: -0.65px;
    margin-bottom: 42px;

    @media (max-width: 1100px) {
      font-size: 45px;
      line-height: 67px;
    }

    @media (max-width: 500px) {
      font-size: 41px;
      line-height: 57px;
    }

    @media (max-width: 385px) {
      font-size: 30px;
      line-height: 47px;
    }

    @media (max-width: 347px) {
      max-width: 215px;
    }
  }

  @keyframes check {
    0% {
    }

    50% {
    }

    100% {
    }
  }

  &__info {
    position: relative;
    padding: 20px 25px;
    @include flex(column);
    border-radius: 8px;
    margin-bottom: 170px;
    background: white;
    box-shadow: 0px 6px 10px 0px rgba(0, 5, 50, 0.15);
    color: #111439;
    width: 100%;
    transition: 0.2s linear;
    overflow: hidden;
    z-index: 1;

    &:hover .hero__info-title {
      transform: translateY(10px);
    }

    &:hover .hero__info-description {
      transform: translateY(0px);
    }

    &:hover .hero__info-description-bottom {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    &-title {
      transform: translateY(30px);
      font-family: var(--font-family-inter);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-left: none;
      margin-bottom: 32px;
      z-index: 1;
      transition: 0.2s linear;

      @media (max-width: 1100px) {
        transform: translateY(10px);
      }
    }

    &-title-mobile {
      margin-bottom: 20px;
    }

    &-description {
      transform: translateY(30px);
      margin-bottom: 10px;
      color: #425466;
      font-family: var(--font-family-uni-sans);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.36px;
      z-index: 1;
      transition: 0.2s linear;

      @media (max-width: 1100px) {
        transform: translateY(0px);
      }
    }

    &-description-mobile {
      font-family: var(--font-family-uni-sans);
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 14px;
    }

    &-description-bottom {
      visibility: hidden;
      opacity: 0;
      // max-height: 0;
      color: #425466;
      font-family: var(--font-family-uni-sans);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.36px;
      z-index: 1;
      transform: translateY(56px);
      transition: 0.2s linear;

      @media (max-width: 1100px) {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }

    &-img {
      position: absolute;
      right: 22px;
      bottom: -24px;
      z-index: 0;
      transition:
        bottom 0.2s linear,
        opacity 0.2s linear,
        filter 0.2s linear;
      filter: contrast(0%) opacity(10%);

      @media (max-width: 1100px) {
        bottom: 0px;
        filter: none;
      }
    }

    &:hover .hero__info-img {
      bottom: 0px;
      filter: none;
    }

    &-mobile {
      margin-bottom: 60px;
    }
  }
}

// .background {
//     display: flex;
//     justify-content: center;
//     position: absolute;
//     bottom: -60px;
//     left: -3px;
//     height: 100vh;
//     width: 100vw;
//     background: #F6F9FC;
//     padding: 0 15px;
//     z-index: 0;
// }

.borders-inner {
  position: relative;
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  max-width: 1080px;
}

.borders-container {
  position: absolute;
  max-width: 1080px;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  left: 0%;
  top: 0;
}

/* .container-one {
    position: absolute;
    height: 100vh;
    width: 176vw;
    background: rgba(0, 71, 255, 0.20);
    rotate: 25deg;
    left: -51%;
    bottom: 91%;
  } */

.background-container-one {
  position: absolute;
  right: 0;
  top: 0;
  width: 80vw;
  height: 60vh;
  animation: animation infinite 10s;
  animation-delay: 2s;
  z-index: -1;
}

//   .background-container-two {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     width: 80vw;
//     height: 100vh;
//     border: 1px solid black;
//   }

.borders {
  position: absolute;
  max-width: 1080px;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  z-index: 0;
}

.tr-white {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -467px;
  right: 0;
  border-left: 20px solid transparent;
  border-top: 467px solid transparent;
  border-right: 100vw solid #f6f9fc;
  z-index: 0;
}

.borders-triangle {
  width: 100%;
  height: 100%;
  max-width: 1080px;
}

//   .tr-one {
//     position: absolute;
//     width: 0px;
//     height: 0px;
//     top: -249vh;
//     left: -235vw;
//     border-top: 500vh solid rgba(0, 71, 255, 0.20);
//     border-left: 500vw solid transparent;
//     box-shadow: 128px 0px 105px 0px rgba(0, 71, 255, 0.08) inset;
//     filter: drop-shadow(-60px 0px 200px rgba(0, 166, 255, 0.30));
//     // animation: animation infinite 30s;
//     animation-delay: 1s;
//     z-index: -3;
//     rotate: -8deg
//   }

.triangles-container {
  position: absolute;
}

.background-container {
  position: absolute;
  top: -120px;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(205deg, #0075ff 21.22%, #c3eaff 66.15%);
  overflow: hidden;
  z-index: 0;
}

.border-hero__left {
  @media (max-width: 800px) {
    left: 15px;
  }
}

.border-hero__right {
  @media (max-width: 800px) {
    top: -40px;
    height: calc(100% + 40px);
    right: 15px;
  }
}

.final-triangle {
  position: absolute;
  display: inline-block;
  border-bottom: 445px solid #fff;
  border-left: calc(100vw + 10px) solid transparent;
  top: -31px;
  left: -8px;
  z-index: -2;

  @media (max-width: 800px) {
    position: absolute;
    display: inline-block;
    border-bottom: 82px solid #fff;
    border-left: calc(100vw + 8px) solid transparent;
    top: 161px;
    left: -6px;
    z-index: -2;
  }
}

.background-white {
  position: absolute;
  background: white;
  height: 102%;
  width: 100vw;
  top: 413px;
  left: 0;
  z-index: -2;

  @media (max-width: 1442px) {
    position: absolute;
    background: white;
    height: 102%;
    width: 100vw;
    top: 413px;
    left: 0;
    z-index: -2;
  }

  @media (max-width: 800px) {
    position: absolute;
    background: white;
    height: 102%;
    width: 100vw;
    top: 242px;
    left: 0;
    z-index: -2;
  }
}

.background-triangles {
  position: absolute;
  // border: 1px solid black;
  background: blue;
  top: -121px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -4;
  overflow: hidden;

  @media (max-width: 500px) {
    top: -96px;
  }
}

.background-inner {
  height: 100vh;
  width: 100vw;
  background: radial-gradient(
    91.01% 98.76% at 48.71% 49.95%,
    #24caff 9.8%,
    #0075ff 100%
  );
  z-index: -3;
}

@keyframes tr-one-animation {
  0% {
    left: 20px;
    rotate: 26deg;
  }

  30% {
    left: -500px;
    rotate: 26deg;
  }

  60% {
    left: -500px;
    rotate: 36deg;
  }

  80% {
    left: 20px;
    rotate: 36deg;
  }

  100% {
    left: 20px;
    rotate: 26deg;
  }
}

@keyframes tr-one-animation-mobile {
  0% {
    left: 20px;
    rotate: 26deg;
  }

  30% {
    left: -80px;
    rotate: 26deg;
  }

  60% {
    left: -80px;
    rotate: 36deg;
  }

  80% {
    left: -20px;
    rotate: 36deg;
  }

  100% {
    left: 20px;
    rotate: 26deg;
  }
}

.tr-one {
  position: absolute;
  top: -1140px;
  left: 20px;
  width: 200%;
  height: 200%;
  background: rgba(0, 71, 255, 0.2);
  box-shadow: 128px 0px 105px 0px rgba(0, 71, 255, 0.08) inset;
  filter: drop-shadow(-60px 0px 200px rgba(0, 166, 255, 0.3));
  animation: tr-one-animation 25s infinite ease-in-out;
  rotate: 26deg;
  z-index: 2;

  @media (max-width: 800px) {
    top: -1445px;
    width: 320%;
    animation: tr-one-animation-mobile 15s infinite ease-in-out;
  }

  @media (max-width: 430px) {
    top: -1445px;
    width: 320%;
  }
}

@keyframes animation-2 {
  0% {
    left: -100px;
    rotate: 120deg;
  }

  30% {
    left: -200px;
    rotate: 120deg;
  }

  60% {
    left: -500px;
    rotate: 100deg;
  }

  80% {
    left: -300px;
    rotate: 120deg;
  }

  100% {
    left: -100px;
  }
}

@keyframes animation-2-mobile {
  0% {
    top: -120px;
    left: 400px;
    height: 156%;
  }

  30% {
    top: -220px;
    left: 400px;
    height: 156%;
  }

  60% {
    top: -220px;
    left: 350px;
    height: 156%;
  }

  80% {
    top: -120px;
    left: 400px;
    height: 156%;
  }

  100% {
    top: -120px;
    left: 400px;
    height: 156%;
  }
}

.tr-two {
  top: 0;
  left: -100px;
  position: absolute;
  height: 200%;
  width: 200%;
  background: linear-gradient(-61deg, #3378ff 33.59%, #50dfff 48.26%);
  /* filter: drop-shadow(-61px 4px 200px
  px
  rgba(0, 41, 255, 0.58)); */
  rotate: 0;
  z-index: 1;
  animation: animation-2 30s infinite ease-in-out;
  rotate: 120deg;

  @media (max-width: 800px) {
    top: -120px;
    left: 400px;
    height: 156%;
    animation: animation-2-mobile 30s infinite ease-in-out;
    background: linear-gradient(140deg, #3378ff 33.59%, #50dfff 48.26%);
  }

  @media (max-width: 430px) {
    top: -120px;
    left: 400px;
    height: 156%;
    background: linear-gradient(140deg, #3378ff 33.59%, #50dfff 48.26%);
  }
}
