@import "../../assets/styles/mixins/flex.scss";
@import "../../assets/styles/typography.scss";

.module-block {
  &__section {
    background: #f6f9fc;
  }

  &__container {
    @include flex(row, flex-start, flex-start, nowrap);
    position: relative;
    gap: 84px;
    padding-top: 160px;
    width: 100%;
    z-index: 1;

    @media (max-width: 1100px) {
      padding-top: 60px;
      gap: 32px;
      @include flex(column, flex-start, center, nowrap);
    }
  }

  &__block {
    font-family: var(--font-family-inter);
    color: var(--color-text-main);
    flex: 1 1 433px;
    width: 100%;
    min-width: 300px;
    max-width: 510px;
    padding: 25px 18px;
    border-radius: 8px;
    background: white;
    margin-bottom: 40px;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 6px 10px 0px rgba(0, 5, 50, 0.15);

    @media (max-width: 340px) {
      min-width: 100%;
    }

    &-mobile {
      flex: none;
      margin-bottom: 70px;

      @media (max-width: 500px) {
        margin-bottom: 0;
      }
    }
  }

  &__el {
    @include flex(row, flex-start, center, nowrap);
    font-family: var(--font-family-uni-sans);
    margin-bottom: 24px;
    gap: 16px;

    &-title {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-description {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &-icon {
      width: 40px;
      height: 40px;
    }
  }

  div:last-child > &__el {
    margin-bottom: 0;
  }

  &__right img {
    image-rendering: pixelated;

    @media (max-width: 546px) {
      image-rendering: auto;
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 20px;

    &-mobile {
      align-self: flex-start;
    }
  }

  &__description {
    color: var(--color-text-main);
    font-family: var(--font-family-uni-sans);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding: 0 25px;
    margin-bottom: 30px;
  }

  &__img {
    width: 100%;
    max-width: 510px;
    margin-bottom: 15px;
  }

  &__scheme {
    width: 100%;

    @media (max-width: 575px) {
      display: none;
    }
  }

  &__scheme-mobile {
    display: none;
    width: 100%;
    height: 100%;

    @media (max-width: 575px) {
      display: block;
    }
  }
}
