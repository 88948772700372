@import "../../../assets/styles/mixins/flex.scss";

.react-tel-input .selected-flag {
  display: none;
}

.flag-dropdown {
  display: none;
}

.react-tel-input {
  @media (max-width: 800px) {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 0 !important;
  }
}

.react-tel-input .form-control {
  font-family: var(--font-family-inter);
  font-size: 16px !important;
  padding-left: 0 !important;
  background: none !important;
  border: none !important;

  @media (max-width: 800px) {
    margin-bottom: 10px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

.connect-us {
  @include flex(row, center, center);
  position: fixed;
  // background: black;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10000;
  // background-color: rgba(black ,0.4);

  &__container {
    position: relative;
    @include flex(column, flex-start, center, nowrap);
    padding: 40px 60px;
    max-width: 826px;
    height: fit-content;
    max-height: 100vh;
    background: #fff;
    border-radius: 4px;
    overflow: auto;

    @media (max-width: 850px) {
      margin: 0 10px;
      padding: 10px;
    }
  }

  &__title {
    margin-bottom: 40px;
    color: #1a1f36;
    text-align: center;
    // font-family: SF UI Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // letter-spacing: 0.48px;
  }

  &__input {
    background-color: #f6f7fa;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #f6f7fa;
    width: 100%;
    max-height: 54px;
    padding: 17px 19px;
    outline: none;
  }

  &__phone {
    background-color: #f6f7fa;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #f6f7fa;
    width: 100px;
    max-height: 54px;
    padding: 17px 19px;
    outline: none;
  }

  &__inputs {
    @include flex(row, null, null, nowrap);
    gap: 6px;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__description {
    margin-bottom: 43px;
    color: #959ead;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // letter-spacing: 0.24px;
  }

  &__cross {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      opacity: 0.3;
    }
  }

  &__agreement {
    @include flex(row, flex-start, center, nowrap);
    margin-top: 10px;
    gap: 6px;

    > span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    color: white;
    background: black;
    width: fit-content;
    border-radius: 100px;
    height: 34px;
    padding: 0 74px;
    cursor: pointer;
    transition: all linear 0.2s;

    &:hover {
      opacity: 0.3;
    }
  }

  &__button {
    @include flex(row, center);
    margin-top: 40px;
    margin-bottom: 18px;
    width: 100%;
  }

  &__error {
    border: 1px solid red;
  }

  &__error-text {
    display: flex;
    flex-direction: row;
    gap: 2px;
    margin-top: 10px;
    color: red;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__spinner {
    margin-left: 10px;
  }

  &__button {
    display: flex;
    align-items: center;
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    margin-top: 10px;
    color: green;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__comment {
    margin-top: 10px;
  }
}

input[type="checkbox"]::before {
  position: absolute;
  left: 3px;
  top: -3px;
  content: url(../../../assets/icons/check.svg);
  width: 9px;
  height: 7px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.blocked {
  opacity: 0.1;
  pointer-events: none;
}

#svg__test {
  width: 40px;
  display: block;
  margin: 2px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  &.success {
    color: #73af55;
  }
  &.error {
    color: #d06079;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
