@import "./assets/styles/mixins/flex.scss";

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  z-index: 1;

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    z-index: 2;
  }
}

.overlay-dark {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  opacity: 50%;
  z-index: 2;
}

.triangle {
  width: 200vw;
  height: 144vh;
  position: fixed;
  background: rgba(54, 90, 255, 0.1);
  opacity: 0.5;
  transform: rotate(20deg);
  top: -50vw;
  left: -45vw;
  z-index: 0;

  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  animation-name: cycle;
  z-index: -1;

  @media (max-width: 1100px) {
    top: -100vw;
    left: -45vw;
  }
}

.triangle-dark {
  width: 300vw;
  height: 300vh;
  position: fixed;
  background: rgba(54, 90, 255, 0.1);
  opacity: 1;
  transform: rotate(30deg);
  top: 60%;
  left: 40%;
  z-index: -1;

  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  animation-name: cycle2;

  @media (max-width: 1100px) {
    top: 20%;
    left: 40%;
  }
}

.background {
  &__main {
    background-color: #f9f9f9;
  }
}

@keyframes cycle {
  0% {
    top: -50vw;
    right: 0;
  }

  50% {
    top: -40vw;
  }

  100% {
    top: -30vw;
  }
}

@keyframes cycle2 {
  0% {
    top: 50%;
  }

  50% {
    top: 70%;
  }

  100% {
    top: 60%;
  }
}
