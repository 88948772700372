@import "../../../assets/styles/mixins/flex.scss";
@import "../../../assets/styles/typography.scss";

.test {
  z-index: 3;
}

.menu {
  &__container {
    @include flex(column, flex-start);
    top: 0;
    right: 0;
    height: 100vh;
    width: 80vw;
    position: fixed;
    background-color: white;
    color: var(--color-text-main);
    z-index: 1000;
    transform: translateX(101%);
    transition: transform 0.2s ease-in-out;
    font-family: var(--font-family-uni-sans);
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;

    &--opened {
      transform: translateX(0);
    }
  }

  &__header {
    @include flex(row-reverse, space-between, center, nowrap);
    gap: 15px;
    padding: 0 30px;
    height: 97px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.3);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  }

  &__close {
    cursor: pointer;
  }

  &__link {
    @include flex(row, space-between, center);
    width: 100%;
  }

  &__icon {
    @include flex();
  }

  &__links {
    @include flex(column, null, center);
    margin-top: 20px;
    gap: 20px;
    padding: 0 30px;
    width: 100%;
  }

  @media (max-width: 600px) {
    // width: 100%;
  }
}
