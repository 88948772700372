@font-face {
  font-family: "Uni Sans";
  src:
    url("../fonts/Light/UniSansLight.woff2") format("woff2"),
    url("../fonts/Light/UniSansLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Uni Sans";
  src:
    url("../fonts/Thin/UniSansThin.woff2") format("woff2"),
    url("../fonts/Thin/UniSansThin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Uni Sans";
  src:
    url("../fonts/Regular/UniSansRegular.woff2") format("woff2"),
    url("../fonts/Regular/UniSansRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Uni Sans Book";
  src:
    url("../fonts/Book/UniSansBook.woff2") format("woff2"),
    url("../fonts/Book/UniSansBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Uni Sans";
  src:
    url("../fonts/Bold/UniSansBold.woff2") format("woff2"),
    url("../fonts/Bold/UniSansBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Uni Sans";
  src:
    url("../fonts/Heavy/UniSansHeavy.woff2") format("woff2"),
    url("../fonts/Heavy/UniSansHeavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Uni Sans";
  src:
    url("../fonts/Bold/UniSansSemiBold.woff2") format("woff2"),
    url("../fonts/Bold/UniSansSemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Thin/Inter-Thin.woff2") format("woff2"),
    url("../fonts/Thin/Inter-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Extrabold/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/Extrabold/Inter-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Light/Inter-Light.woff2") format("woff2"),
    url("../fonts/Light/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Bold/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Bold/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Extralight/Inter-ExtraLight.woff2") format("woff2"),
    url("../fonts/Extralight/Inter-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Black/Inter-Black.woff2") format("woff2"),
    url("../fonts/Black/Inter-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Semibold/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/Semibold/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Regular/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Regular/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src:
    url("../fonts/Medium/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Medium/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Segoe UI";
  src:
    url("../fonts/Regular/SegoeUI.woff2") format("woff2"),
    url("../fonts/Regular/SegoeUI.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Segoe UI";
  src:
    url("../fonts/Bold/SegoeUI-Bold.woff2") format("woff2"),
    url("../fonts/Bold/SegoeUI-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src:
    url("../fonts/Black/Roboto-Black.woff2") format("woff2"),
    url("../fonts/Black/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src:
    url("../fonts/Bold/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Bold/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src:
    url("../fonts/Regular/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Regular/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src:
    url("../fonts/Medium/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Medium/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src:
    url("../fonts/Thin/Roboto-Thin.woff2") format("woff2"),
    url("../fonts/Thin/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src:
    url("../fonts/Light/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Light/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
