.request {
  &__background {
    background: linear-gradient(61deg, #0075ff 21.22%, #c3eaff 66.15%);
    height: 344px;
    left: -50vw;
    position: absolute;
    top: 217px;
    transform: rotate(-11deg);
    width: 198vw;
    z-index: -1;
  }
}
